import React from "react"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
      <title>Return Shipping Label – Purple Cow Internet 💜🐄</title>
    </Helmet>
    <div className="mx-auto prose prose-xl">
      <h1>Modem Return Shipping</h1>
      <p>
        Please put our modem in a box with any packing material so it does not
        get damaged and take it to any Canada Post where this return ID number:
        PR537649 and return authorization number: P2452, (both case sensitive)
        can be used to get a free return shipping label! It should get sent back
        to Purple Cow Internet at 347 Bluewater Rd Bedford NS B4B 1Y3. Once
        shipped, please provide us a copy of the tracking number as confirmation
        just in case the modem gets lost in the mail. Thank you!
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
