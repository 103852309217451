import { Link } from "gatsby"
import React, { useState } from "react"
import { useChargebee, useSiteMetadata } from "../hooks"
import { CHARGEBEE_SITE } from "../config"
import { Helmet } from "react-helmet"
import Bars from "@svg-icons/fa-solid/bars.svg"
import Chevron from "@svg-icons/fa-solid/chevron-left.svg"
import { StaticImage } from "gatsby-plugin-image"

const Header: React.FunctionComponent = () => {
  let [menuOpen, setMenuOpen] = useState(false)
  useChargebee()

  let isWaitingListMode = useSiteMetadata()

  return (
    <>
      <Helmet>
        <meta property="og:url" content="https://www.purplecowinternet.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Amazing Internet, Only $60 / month!"
        />
        <meta
          property="og:description"
          content="Purple Cow Internet is a local Nova Scotia business changing the way how internet is being delievered. Amazing customer service, amazing internet, and an amazing deal!"
        />
        <meta
          name="description"
          content="Purple Cow Internet is a local Nova Scotia business changing the way how internet is being delievered. Amazing customer service, amazing internet, and an amazing deal!"
        />
        <meta property="og:image" content="/images/linksharingphoto.png" />
        <meta
          property="og:image:secure_url"
          content="http://drive.google.com/uc?export=view&id=1tdnI5BibkwHmt9wF05EULPnJilcAo05a"
        />
        <meta property="fb:app_id" content="2815092408601788" />

        <meta
          name="facebook-domain-verification"
          content="y51i42mib2v5mror4hyb4pvmpfyzff"
        />
      </Helmet>
      <header className="flex items-center lg:justify-center ">
        {/*my-6 sm:mx-4 lg:mx-20 max-w-7xl <div className="flex-1 hidden mr-auto lg:block"></div> */}
        <div className="pl-4 mr-auto place-self-start lg:place-self-auto lg:ml-auto">
          <Link to="/">
            <StaticImage
              className="w-28"
              layout="constrained"
              src="../content/cow.png"
              alt="Purple Cow Internet 💜🐄"
            />
          </Link>
        </div>
        <button
          className="self-start block mr-4 justify-self-end lg:hidden"
          onClick={() => setMenuOpen(true)}
        >
          <Bars className="w-6 h-6 text-primary-700" />
        </button>
        <nav className="flex-1 hidden ml-auto font-light tracking-widest lg:block pr-4">
          <ul className="flex items-center justify-end gap-4 text-sm text-gray-600 uppercase">
            <li>
              <Link className="hover:text-primary-700" to="/faq">
                FAQS
              </Link>
            </li>
            <li>
              <a
                className="hover:text-primary-700"
                href={`https://${CHARGEBEE_SITE}.chargebeeportal.com`}
              >
                My Account
              </a>
            </li>
            <li>
              {isWaitingListMode ? (
                <a
                  className="inline-block px-4 py-2 text-white cursor-pointer bg-primary-700"
                  href="https://purplecowinternet.squarespace.com/waiting-list"
                >
                  Join the Waiting List
                </a>
              ) : (
                <Link
                  className="inline-block px-4 py-2 text-white cursor-pointer bg-primary-700"
                   to="/join-the-herd"
                >
                  Join the Herd
                </Link>
              )}
            </li>
          </ul>
        </nav>
        {menuOpen ? (
          <nav className="fixed top-0 bottom-0 left-0 right-0 z-50 w-screen min-h-screen px-8 py-8 tracking-wider lg:hidden bg-primary-800">
            <ul className="flex flex-col gap-4 text-2xl font-bold text-white">
              <li className="text-xl font-light">
                <button
                  className="flex items-center"
                  onClick={() => setMenuOpen(false)}
                  onKeyDown={(e) => e.key === "Enter" && setMenuOpen(false)}
                >
                  <Chevron className="w-3 h-3 mr-2" /> Back
                </button>
              </li>
              <li>
                <Link to="/faq">FAQS</Link>
              </li>
              <li>
                <a href={`https://${CHARGEBEE_SITE}.chargebeeportal.com`}>
                  My Account
                </a>
              </li>
              <li>
                {isWaitingListMode ? (
                  <a
                    className="inline-block px-4 py-2 text-white cursor-pointer bg-primary-700"
                    href="https://purplecowinternet.squarespace.com/waiting-list"
                  >
                    Join the Waiting List
                  </a>
                ) : (
                  <Link
                    className="inline-block px-4 py-2 text-white cursor-pointer bg-primary-700"
                     to="/join-the-herd"
                  >
                    Join the Herd
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        ) : null}
      </header>
    </>
  )
}

export default Header
