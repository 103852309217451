import { Link } from "gatsby"
import React from "react"
import { useSiteMetadata } from "../hooks"
import Facebook from "@svg-icons/fa-brands/facebook-f.svg"
import Instagram from "@svg-icons/fa-brands/instagram.svg"

const Footer = () => {
  let isWaitingListMode = useSiteMetadata()
  return (
    <footer className="mt-10 mb-20">
      <div className="flex items-center justify-center mb-4 gap-x-6 ">
        <a
          aria-label="Facebook page"
          href="//web.facebook.com/purplecowinternet"
        >
          <Facebook className="w-8 h-8 text-gray-900 " />
        </a>
        <a
          aria-label="Instagram page"
          href="//www.instagram.com/purplecowinternet/"
        >
          <Instagram className="w-8 h-8 text-gray-900" />
        </a>
      </div>
      <div className="flex items-center justify-center text-lg gap-x-4">
        {isWaitingListMode ? (
          <a
            className="text-gray-500"
            href="https://purplecowinternet.squarespace.com/waiting-list"
          >
            Join the Waiting List
          </a>
        ) : (
          <Link  to="/join-the-herd" className="text-gray-500">
            Join the Cow
          </Link>
        )}

        <Link to="/terms-of-use" className="text-gray-500">
          Terms of Use
        </Link>
      </div>
    </footer>
  )
}

export default Footer
